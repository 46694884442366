<template>
  <div>
    <div v-if="isLoading" class="flex w-full h-full p-24">
      <img src="@/assets/images/loading1.gif" alt="" width="50" class="mx-auto">
    </div>
    <vx-card v-else class="p-4">
      <form action="">
        <div class="vx-row mb-6">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.code')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="{ required: product.isActive || product.canPublished }" name="code" class="w-full" v-model="product.code" placeholder="101012345" />
            <span class="text-danger text-sm" v-show="errors.has('code')">{{ errors.first('code') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.bar_code')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="'required|numeric'" class="w-full" name="barcode" v-model="product.bar_code" placeholder="4657867897" />
            <span class="text-danger text-sm" v-show="errors.has('barcode')">{{ errors.first('barcode') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.title')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <div class="vx-row">
                <div class="vx-col my-2 md:my-0 md:w-1/3 w-full">
                  <vs-input v-validate="'required'" class="w-full" name="name_tm" placeholder="Turkmen" v-model="product.name_tm" />
                  <span class="text-danger text-sm" v-show="errors.has('name_tm')">{{ errors.first('name_tm') }}</span>
                </div>
                <div class="vx-col my-2 md:my-0 md:w-1/3 w-full">
                  <vs-input v-validate="'required'" class="w-full" name="name_ru" placeholder="Russian" v-model="product.name_ru" />
                  <span class="text-danger text-sm" v-show="errors.has('name_ru')">{{ errors.first('name_ru') }}</span>
                </div>
                <div class="vx-col my-2 md:my-0 md:w-1/3 w-full">
                  <vs-input v-validate="'required'" class="w-full" name="name_en" placeholder="English" v-model="product.name_en" />
                  <span class="text-danger text-sm" v-show="errors.has('name_en')">{{ errors.first('name_en') }}</span>
                </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.description')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <div class="vx-row">
              <div class="vx-col my-2 md:my-0 md:w-1/3 w-full">
                <vs-textarea v-validate="'required'" class="w-full mb-0" name="descr_tm" rows="4" placeholder="Turkmen" v-model="product.description_tm"></vs-textarea>
                <span class="text-danger text-sm" v-show="errors.has('descr_tm')">{{ errors.first('descr_tm') }}</span>
              </div>
              <div class="vx-col my-2 md:my-0 md:w-1/3 w-full">
                <vs-textarea v-validate="'required'" class="w-full mb-0" name="descr_ru" rows="4" placeholder="Russian" v-model="product.description_ru"></vs-textarea>
                <span class="text-danger text-sm" v-show="errors.has('descr_ru')">{{ errors.first('descr_ru') }}</span>
              </div>
              <div class="vx-col my-2 md:my-0 md:w-1/3 w-full">
                <vs-textarea v-validate="'required'" class="w-full mb-0" name="descr_en" rows="4" placeholder="English" v-model="product.description_en"></vs-textarea>
                <span class="text-danger text-sm" v-show="errors.has('descr_en')">{{ errors.first('descr_en') }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('categories')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <v-select multiple v-validate="'required_if:canPublished,true'" :options="categories" :label="`name_${$i18n.locale}`" name="categories" :placeholder="$t('select') + ' ' + $t('categories')" v-model="product.categories" />
            <span class="text-danger text-sm" v-show="errors.has('categories')">{{ errors.first('categories') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('brand')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <v-select :options="brands" name="brand" :label="`name_${$i18n.locale}`" :placeholder="$t('select') + ' ' + $t('brand')" v-model="product.brandId" :reduce="opt => opt.id" />
            <span class="text-danger text-sm" v-show="errors.has('brand')">{{ errors.first('brand') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('supplier')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <v-select :options="suppliers" name="supplier" label="company_name" :placeholder="$t('select') + ' ' + $t('supplier')" v-model="product.supplierId" :reduce="opt => opt.id" />
            <span class="text-danger text-sm" v-show="errors.has('supplier')">{{ errors.first('supplier') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.stockQt')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="{ required: true, min_value: product.canPublished ? 1 : 0 }" name="stockQt" class="w-full" type="number" v-model="product.stock_quantity" />
            <span class="text-danger text-sm" v-show="errors.has('stockQt')">{{ errors.first('stockQt') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.minStockQt')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="{ required: true, min_value: product.canPublished ? 1 : 0 }" name="stockMinQt" class="w-full" type="number" v-model="product.stock_min" />
            <span class="text-danger text-sm" v-show="errors.has('stockMinQt')">{{ errors.first('stockMinQt') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.price')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="'required_if:canPublished,true'" name="price" type="number" class="w-full" v-model="product.given_price" />
            <span class="text-danger text-sm" v-show="errors.has('price')">{{ errors.first('price') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.weight')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="'required_if:canPublished,true'" name="weight" class="w-full" v-model="product.weight" placeholder="3kg" />
            <span class="text-danger text-sm" v-show="errors.has('weight')">{{ errors.first('weight') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.volume')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <vs-input v-validate="'required_if:canPublished,true'" name="volume" class="w-full" v-model="product.volume" placeholder="10cm3" />
            <span class="text-danger text-sm" v-show="errors.has('volume')">{{ errors.first('volume') }}</span>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.new')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <li class="vx-col my-2 ml-2 mr-4 flex">
              <vs-switch name="isBulk" v-model="product.isNew" />
              <label class="ml-3">{{$t('fields.isNewProduct')}}</label>
            </li>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.hit')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <li class="vx-col my-2 ml-2 mr-4 flex">
              <vs-switch name="isBulk" v-model="product.isHit" />
              <label class="ml-3">{{$t('fields.isHitProduct')}}</label>
            </li>
          </div>
        </div>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.isBulk')}}</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <li class="vx-col my-2 ml-2 mr-4 flex">
              <vs-switch disabled name="isBulk" ref="isBulk" v-model="product.isBulk" />
              <label class="ml-3">{{ $t('fields.isBulk') }}?</label>
            </li>
          </div>
        </div>
        <template v-if="product.isBulk">
          <div class="vx-row mb-6 mt-4">
            <div class="vx-col w-full sm:w-2/12 pt-2">
              <span class="text-lg font-semibold">{{$t('fields.bulkPrice')}}:</span>
            </div>
            <div class="vx-col sm:w-10/12 w-full">
              <vs-input v-validate="'required_if:isBulk,true'" name="priceBulk" type="number" class="w-full" v-model="product.price_bulk" />
              <span class="text-danger text-sm" v-show="errors.has('priceBulk')">{{ errors.first('priceBulk') }}</span>
            </div>
          </div>
          <div class="vx-row mb-6 mt-4">
            <div class="vx-col w-full sm:w-2/12 pt-2">
              <span class="text-lg font-semibold">{{$t('fields.minBulkOrder')}}:</span>
            </div>
            <div class="vx-col sm:w-10/12 w-full">
              <vs-input v-validate="'required_if:isBulk,true'" name="bulkMin" class="w-full" v-model="product.bulk_min" />
              <span class="text-danger text-sm" v-show="errors.has('bulkMin')">{{ errors.first('bulkMin') }}</span>
            </div>
          </div>
        </template>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{$t('fields.type')}}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <v-select v-validate="'required_if:canPublished,true'" class="w-full" :options="types" name="type" ref="type" :placeholder="$t('selectOne')" v-model="product.type" :label="`name_${$i18n.locale}`" :reduce="opt => opt.name_en" />
            <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
          </div>
        </div>
        <template v-if="product.type === 'both' || product.type === 'express'">
          <div class="vx-row mb-6 mt-4">
            <div class="vx-col w-full sm:w-2/12 pt-2">
              <span class="text-lg font-semibold">{{ $t('fields.maxExpressOrderQt') }}:</span>
            </div>
            <div class="vx-col sm:w-10/12 w-full">
              <vs-input v-validate="'required_if:type,both,express'" name="expressMax" class="w-full" v-model="product.express_max" />
              <span class="text-danger text-sm" v-show="errors.has('expressMax')">{{ errors.first('expressMax') }}</span>
            </div>
          </div>
        </template>
        <div class="vx-row mb-6 mt-4">
          <div class="vx-col w-full sm:w-2/12 pt-2">
            <span class="text-lg font-semibold">{{ $t('fields.active') }}:</span>
          </div>
          <div class="vx-col sm:w-10/12 w-full">
            <li class="vx-col my-2 ml-2 mr-4 flex">
              <vs-switch :disabled="!product.isActive && !product.product_images.length"  name="isActive" ref="isActive" v-model="product.isActive" />
              <label class="ml-3">{{$t('isActive')}}?</label>
            </li>
            <li class="vx-col my-2 ml-2 mr-4 flex">
              <vs-switch :disabled="!product.canPublished && !product.product_images.length" v-validate="'required_if:isActive,true'" name="canPublished" ref="canPublished" v-model="product.canPublished" />
              <label class="ml-3">{{$t('canBePublished')}}?</label>
              <span v-if="!product.canPublished && !product.product_images.length" class="text-danger text-sm">({{ $t('imageNotAdded') }})</span>
            </li>
            <span class="text-danger text-sm" v-show="errors.has('canPublished')">{{ errors.first('canPublished') }}</span>
          </div>
        </div>
        <vs-row>
          <vs-button type="filled" @click.prevent="submitForm" class="ml-auto">{{ $t('submit') }}</vs-button>
          <vs-button type="border" color="warning" @click.prevent="$router.back()" class="ml-4 mr-auto block">{{ $t('cancel') }}</vs-button>
        </vs-row>
      </form>
    </vx-card>
  </div>
</template>

<script>
import vSelect from 'vue-select'
export default {
  components: {
    vSelect
  },
  data () {
    return {
      product: {},
      types: [
        {name_en: 'both', name_tm: 'Ikisi hem', name_ru: 'Оба'},
        {name_en: 'market', name_tm: 'Market', name_ru: 'Маркет'},
        {name_en: 'express', name_tm: 'Ekspress', name_ru: 'Экспресс'}
      ],
      images: [],
      isLoading: true,
      categories: [],
      brands: [],
      suppliers: []
    }
  },
  async created () {
    await this.fetchProduct()
    await this.fetchCategories()
    await this.fetchBrands()
    await this.fetchSuppliers()
    this.isLoading = false
  },
  methods: {
    async fetchProduct () {
      await this.$http.get(`/products/${this.$route.params.id}`).then(response => {
        if (response.status === 200) {
          this.product = response.data
        }
      })
    },
    async fetchCategories () {
      await this.$http.get('/categories', { params: { isActive: true } }).then(response => {
        if (response.status === 200) {
          this.categories = response.data.data
        }
      })
    },
    async fetchBrands () {
      await this.$http.get('/brands', { params: { isActive: true } }).then(response => {
        if (response.status === 200) {
          this.brands = response.data.data
        }
      })
    },
    async fetchSuppliers () {
      await this.$http.get('/suppliers', { params: { isActive: true } }).then(response => {
        if (response.status === 200) {
          this.suppliers = response.data.data
        }
      })
    },
    successUpload () {
      this.$vs.notify({
        title: this.$t('notify.success'),
        text: `${this.$t('image')} ${this.$t('notify.sAdded')}`,
        iconPack: 'feather',
        icon: 'icon-check-circle',
        color: 'primary'
      })
    },
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.patch(`/products/edit/${this.product.uuid}`, {
            ...this.product,
            categories: this.product.categories.map(cat => cat.id)
          }).then(response => {
            if (response.status === 200) {
              this.$vs.notify({
                title: this.$t('notify.success'),
                text: `${this.$t('product')} ${this.$t('notify.sUpdated')}`,
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'primary'
              })
              this.$router.back()
            }
          }).catch(err => {
            this.$vs.notify({
              title: this.$t('notify.error'),
              text: err.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
        } else {
          this.$vs.notify({
            title: this.$t('notify.error'),
            text: this.$t('notify.formInvalid'),
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger'
          })
        }
      })
    }
  }
}
</script>
